import axios from 'axios' 

import { jwtWrapper, jwtConfig } from '@src/auth/jwt/useJwt'

const PROTOCOL = process.env.REACT_APP_REST_API_PROTOCOL
const HOST = process.env.REACT_APP_REST_API_HOST
const PORT = process.env.REACT_APP_REST_API_PORT

const api = axios.create({baseURL: `${PROTOCOL}://${HOST}:${PORT}/api/v1`})

jwtWrapper(api, jwtConfig)

export default api

