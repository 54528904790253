import * as actions from '../../actions/admin/index'

// ** Initial State
const initialState = {
    users: [],
    accounts: [],
    customers: [],
    customerstransactions: [],
    onchaintransactions: [],
    rates: []
  }

  const adminReducer = (state = initialState, action) => {
    switch (action.type) {
     case actions.LOAD_USERS:
            return { ...state, users: action.payload}
    case actions.LOAD_ACCOUNTS:
            return { ...state, accounts: action.payload}
    case actions.LOAD_CUSTOMERS:
            return { ...state, customers: action.payload}
    case actions.LOAD_CUSTOMERS_TRANSACTIONS:
            return { ...state, customerstransactions: action.payload}
    case actions.LOAD_ONCHAIN_TRANSACTIONS:
            return { ...state, onchaintransactions: action.payload }
    case actions.LOAD_RATES:
            return { ...state, rates: action.payload }
        default: 
          return state
    }
  }
  
  export default adminReducer
  