import api from './index'

const MOCK_TRANSFER = Boolean(process.env.REACT_APP_MOCK_TRANSFER === 'true')

export async function openSavingsAccount(asset, amount, customerId, cbToken = '') {
    return await api.post('/earn/savings/account', {
        amount,
        asset,
        customerId,
        cb_token: cbToken
    })
}

export async function openSavingsAccountWithOnRamper(paymentData, customerId = null) {
    const payload = {
        payment_data: paymentData,
        source: 'onramper',
        customerId
    }

    if (MOCK_TRANSFER) {
        console.warn('Executing account order with mock! AVOID IN PRODUCTION!')
        payload.payment_data.mock = true
    }

    return await api.post('/earn/savings/account', payload)
}

export async function depositToSavingsAccount(
    accountId,
    amount,
    source,
    txHash = null,
    paymentData = null) {

    if (paymentData !== null) {
        return await api.post(`/earn/savings/account/${accountId}/deposit`, {
            payment_data: paymentData
        })
    } else {
        return await api.post(`/earn/savings/account/${accountId}/deposit`, {
            amount
        })
    }

}

export async function closeSavingsAccount(accountId) {
    return await api.post(`/earn/savings/account/${accountId}/close`)
}


export async function withdrawFromSavingsAccount(accountId, amount) {
    return await api.post(`/earn/savings/account/${accountId}/withdraw`, {
        amount
    })
}

export async function getAccountsStats(accoundId, interval) {
    return api.get(`/earn/savings/account/${accoundId}/stats/${interval}`)
}

export async function getAccountBalanceById(accountId) {
    return api.get(`/account/${accountId}`)
}

export async function getAccountTransactionsById(accountId) {
    return api.get(`/earn/savings/account/${accountId}/transactions`)
}

export async function getAccountCustomerTransactionsById(accountId) {
    return api.get(`/account/${accountId}/transactions/1/50`)
}

export async function getAccountInterestById(accountId) {
    return api.get(`/earn/savings/account/${accountId}/interest`)
}
