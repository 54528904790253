import * as actions from '../../actions/transaction'

// ** Initial State
const initialState = {
    to: {},
    from: {},
    type: {},
    amount: {}
  }

  const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.SELECT_DESTINATION_WALLET:
          console.log()
      default:
          return state
    }
  }
  
  export default transactionReducer
  