import { batch } from 'react-redux'
import jwt from 'jsonwebtoken'
import { toast } from 'react-toastify'
import { getUserData, isUserLoggedIn } from '@utils'

import { authLogin, authLogout } from '../../../api/auth'
import { disconnectWallet, coinbaseExchangeCode } from '../../../api/coinbase'
/* import { magic } from '../../../api/magic'
 */import history from '../../../router/history'

import { jwtConfig } from '@src/auth/jwt/useJwt'
import { getOrgFromCustomer } from '../../../api/org'

const config = jwtConfig

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const DISPLAY_LOADER = 'DISPLAY_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const UNAUTHENTICATED_REQUEST = 'UNAUTHENTICATED_REQUEST'
export const REQUEST_COINBASE_2FA_CODE = 'REQUEST_COINBASE_2FA_CODE'
export const LOAD_ORG_INFO = 'LOAD_ORG_INFO'

export const handleLogin = (user) => {

  return dispatch => {
    dispatch({ type: LOGIN_USER, userData: user })
    localStorage.setItem('userData', JSON.stringify(user))
  }
}

export const handleLogout = (browserHistory) => {
  return dispatch => {
    authLogout().then(() => {
      const user =  getUserData()
      // handle source
      if (user?.source === 'direct') {
/*         magic.user.logout().then(() => {
          dispatch({ type: LOGOUT_USER })
        }) */
      } else if (user?.source === 'coinbase') {
        // submitDisconnectCoinbaseWallet
      }
  
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem(config.storageTokenKeyName)
      //localStorage.removeItem(config.storageRefreshTokenKeyName)
      dispatch({ type: LOGOUT_USER })
      browserHistory.push('/login')
    })
  }
}

export const handleUnauthenticatedRequests = (browserHistory) => {
  return dispatch => {
    dispatch({ type: UNAUTHENTICATED_REQUEST })
    dispatch(handleLogout(browserHistory))
  }
}

export const checkIfUserLoggedIn = (browserHistory) => {
  return dispatch => {
    if (isUserLoggedIn()) {
      const user = getUserData()
      dispatch(handleLogin(user))
      browserHistory.push('/savings/app')
    }
  }
}

export const loadUserData = () => {
  return dispatch => {
    const user = getUserData()
    dispatch(handleLogin(user))
  }
}

export const displayLoader = () => {
  return dispatch => {
    dispatch({ type: DISPLAY_LOADER })
  }
}

export const hideLoader = () => {
  return dispatch => {
    dispatch({ type: HIDE_LOADER })
  }
}

export const displayToast = (type, msg) => {
  switch (type) {
    case 'success':
      toast.success(msg)
      break
    case 'error':
      toast.error(msg)
      break
    case 'warning':
      toast.warning(msg)
      break
    case 'info':
      toast.info(msg)
      break
    default:
      toast(msg)
  }
}

export function handleLoginWithEmail(email, password, browserHistory) {
  return async function handleLoginWithEmailThunk(dispatch, getState) {
    return await loginWithEmail({
      email,
      password,
      showUI: true
      // redirectURI: new URL('/login', window.location.origin).href
    }).then((didToken) => {
       return authLogin(didToken)
    }).then((response) => {
      const token = response.data.jwt
      const user = jwt.decode(token)
      localStorage.setItem(config.storageTokenKeyName, token)
      dispatch(handleLogin(user, browserHistory))
    })
  }
}

export function handleLoginWithCoinbaseConnect(code, browserHistory) {
  return async function handleLoginWithCoinbaseConnectThunk(dispatch, getState) {
      await coinbaseExchangeCode(code).then((response) => {
          const token = response.data.jwt
          const user = jwt.decode(token)
          localStorage.setItem(config.storageTokenKeyName, token)
          dispatch(handleLogin(user, browserHistory))
      }).then(() => {
          browserHistory.replace(`/savings/app`)
          displayToast('success', 'Successfully logged in using Coinbase')
      })
  }
}

export function handleError(dispatch, error) {
  try {
      const code = error.response.status
      switch (code) {
          case 401:
              dispatch(handleUnauthenticatedRequests(history))
              break
          case 402:
              dispatch({ type: REQUEST_COINBASE_2FA_CODE, modal2fa: true })
              break
          default:
              dispatch({ type: ERROR_LOADING_DATA,
                          toast: {
                          type: 'error',
                          header: 'Oops. We seemed to have encountered a problem!',
                          message: error.message
                      }
              })
          displayToast('error', error.message)
      }
      dispatch(hideLoader())
  } catch (error) {

  }
}

export function submitDisconnectCoinbaseWallet(customerId, browserHistory) {
  return async function submitDisconnectCoinbaseWalletThunk(dispatch, getState) {
      
      await disconnectWallet(customerId).then((response) => {
          dispatch(handleLogout())
          return response.data
      }).then(() => {
          dispatch(disconnectWalletInfo())
          browserHistory.push(`/savings/landing`)
      }).catch((error) => { handleError(dispatch, error) })

  }
}

export function loadOrgInfo(customerId) {
  return async function loadOrgInfoThunk(dispatch, getState) {
    await getOrgFromCustomer(customerId).then((response) => {
      dispatch({ type: LOAD_ORG_INFO, orgInfo: response.data })
    }).catch((error) => { handleError(dispatch, error) })
  }
}