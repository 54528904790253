import { getEffectiveRates, getInterestEstimate } from '../../../api/rates'

export const LOAD_EFFECTIVE_RATES_WITH_ESTIMATE = 'LOAD_EFFECTIVE_RATES_WITH_ESTIMATE'
export const EXCHANGE_COINBASE_CODE = 'EXCHANGE_COINBASE_CODE'

export function loadEffectiveRatesWithEstimates() {
    return async function loadEffectiveRatesThunk(dispatch, getState) {
        const rates = await getEffectiveRates().then((response) => {
            const ratesWithEstimate = []
            const effectiveRates = response.data.rates
            const awaitedPromises = []
            for (const rateIndex in effectiveRates) {
                const rate = effectiveRates[rateIndex]
                awaitedPromises.push(getInterestEstimate(100, rate.asset, rate.protocol))
            }

            const estimates = Promise.all(awaitedPromises)

            return estimates
        }).then((estimates) => {
            const ratesWithEstimate = []

            for (const index in estimates) {
                ratesWithEstimate.push({
                    asset: estimates[index].config.params.asset,
                    protocol: estimates[index].config.params.protocol,
                    rate: estimates[index].data.current_apy,
                    estimate: estimates[index].data
                })
            }

            return ratesWithEstimate
        })
        dispatch({ type: LOAD_EFFECTIVE_RATES_WITH_ESTIMATE, payload: rates})
    }
}
