
import api from './index'

export async function authLogin(didToken) {
    return await api.post('/auth/login/password', {}, {
       headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${didToken}`
       } 
    })
}

export async function authLogout() {
    return await api.post('auth/logout', {})
}
