const DEV_ENV = process.env.NODE_ENV === 'development'
import {
    getAccountBalanceById,
    getAccountCustomerTransactionsById,
    getAccountInterestById,
    getAccountsStats
} from '../../../api/accounts'
import { handleError } from '../common/index'


// Async Actions
export const LOAD_ACCOUNT_BALANCE = 'LOAD_ACCOUNT_BALANCE'
export const LOAD_ACCOUNT_TRANSACTIONS = 'LOAD_ACCOUNT_TRANSACTIONS'
export const LOAD_ACCOUNT_INTEREST = 'LOAD_ACCOUNT_INTEREST'
export const LOAD_ACCOUNT_STATS = 'LOAD_ACCOUNT_STATS'

// State Actions
export function loadAccountBalance(accountId) {
    return async function loadAccountBalanceThunk(dispatch, getState) {
        await getAccountBalanceById(accountId).then((response) => {
            dispatch({ type: LOAD_ACCOUNT_BALANCE, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export function loadAccountTransaction(accountId) {
    return async function loadAccountTransactionThunk(dispatch, getState) {
        await getAccountCustomerTransactionsById(accountId).then((response) => {
            dispatch({ type: LOAD_ACCOUNT_TRANSACTIONS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export function loadAccountInterest(accountId) {
    return async function loadAccountInterestThunk(dispatch, getState) {
        await getAccountInterestById(accountId).then((response) => {
            dispatch({ type: LOAD_ACCOUNT_INTEREST, payload: response.data})
        })
    }
}

export function loadAccountStats(accountId, interval) {
    return async function loadAccountInterestThunk(dispatch, getState) {
        await getAccountsStats(accountId, interval).then((response) => {
            dispatch({ type: LOAD_ACCOUNT_STATS, payload: response.data})
        })
    }
}
