import { handleError } from '../common'
import {getAllUsers, getAllRates, getAllAccounts, getAllCustomers, getAllCustomersTransactions, getAllOnchainTransactions} from '../../../api/admin'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS'
export const LOAD_CUSTOMERS_TRANSACTIONS = 'LOAD_CUSTOMERS_TRANSACTIONS'
export const LOAD_ONCHAIN_TRANSACTIONS = 'LOAD_ONCHAIN_TRANSACTIONS'
export const LOAD_RATES = 'LOAD_RATES'

export function loadAllUsers() {
    return async function loadAllUsersThunk(dispatch, getState) {
        await getAllUsers().then((response) => {
            dispatch({ type: LOAD_USERS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

 export function loadAllAccounts() {
    return async function loadAllAccountsThunk(dispatch, getState) {
        await getAllAccounts().then((response) => {
            dispatch({ type: LOAD_ACCOUNTS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
} 

export function loadAllCustomers() {
    return async function loadAllCustomersThunk(dispatch, getState) {
        await getAllCustomers().then((response) => {
            dispatch({ type: LOAD_CUSTOMERS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export function loadAllOnchainTransactions() {
    return async function loadAllOnchainTransactionsThunk(dispatch, getState) {
        await getAllOnchainTransactions().then((response) => {
            dispatch({ type: LOAD_ONCHAIN_TRANSACTIONS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export function loadAllRates()  {
    return async function loadAllRatesThunk(dispatch, getState) {
        await getAllRates().then((response) => {
            dispatch({ type: LOAD_RATES, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export function loadAllCustomersTransactions() {
    return async function loadAllCustomersTransactionsThunk(dispatch, getState) {
        await getAllCustomersTransactions().then((response) => {
            dispatch({ type: LOAD_CUSTOMERS_TRANSACTIONS, payload: response.data})
        }).catch((error => {
            handleError(dispatch, error)
        }))
    }
}

export const addSavingaccounts = (payload) => {
    
    return ({
        type:'savingaccounts',
        payload
    })
} 

export const AddRecenTransaction = (payload) => {
    return ({
        type:'recenttransaction',
        payload
    })
}

export const AddOnChainWallet = (payload) => {
    return ({
        type:'onchainwallet',
        payload
    })
}

export const AddOnChainTransaction = (payload) => {
    return ({
        type:'onchaintransaction',
        payload
    })
}
export const AddAssetsData = (payload) => {
    return ({
        type:'addassetsdata',
        payload
    })
}

export const AddAccountsdata = (payload) => {
    return ({
        type:'addaccountsdata',
        payload
    })
}
