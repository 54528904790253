import * as actions from '../../actions/common'

const initialState = {
  userData: {},
  orgInfo: {},
  loading: false,
  authenticated: false,
  modal2fa: false
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_USER:
      return { ...state, userData: action.userData, authenticated: true }
    case actions.LOGOUT_USER:
      return { ...state, userData: {}, authenticated: false }
    case actions.LOAD_ORG_INFO:
      return { ...state, orgInfo: action.orgInfo }
    case actions.DISPLAY_LOADER:
      return { ...state, loading: true }
    case actions.HIDE_LOADER:
      return { ...state, loading: false }
    case actions.UNAUTHENTICATED_REQUEST:
      return { ...state, authenticated: false }
    case actions.REQUEST_COINBASE_2FA_CODE:
      return { ...state, modal2fa: action.modal2fa }
    default:
      return state
  }
}

export default commonReducer
