// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage
}

// ** init middleware
const middleware = [thunk, createDebounce()]

if (process.env.NODE_ENV === "development") {
    middleware.push(logger)
}

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, rootReducer)

// ** Create store
const store = createStore(
    persistedReducer, {},
    composeEnhancers(applyMiddleware(...middleware)
))

const persistor = persistStore(store)

export { store, persistor }
