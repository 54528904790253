import api from './index'

export async function getAllUsers() {
    return await api.get('admin/users')
}

export async function getAllAccounts() {
    return await api.get('/admin/accounts/1/50')
}

export async function getAllCustomers() {
    return await api.get('/customers/1/50')
}

export async function getAllCustomersTransactions() {
    return await api.get('/admin/transactions/1/50')
}

export async function getAllOnchainTransactions() {
    return await api.get('/on-chain/transactions/1/50')
}

export async function getAllRates() {
    return await api.get('/assets')
}
