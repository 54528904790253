import * as actions from '../../actions/savingslanding'

// ** Initial State
const initialState = {
    rates: []
}

  const savingsLandingReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.LOAD_EFFECTIVE_RATES_WITH_ESTIMATE:
        return { ...state, rates: action.payload }
      default:
          return state
    }
  }
  
  export default savingsLandingReducer
  