// ** Redux Imports
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import common from './common'
import navbar from './navbar'
import layout from './layout'
import admin from './admin/admin'
import savingsapp from './savingsapp'
import savingslanding from './savingslanding'
import savingaccountview from './savingaccountview'
import accounttransaction from './transaction'
import walletmanager from './wallet'

const appReducer = combineReducers({
  common,
  navbar,
  layout,
  admin,
  savingsapp,
  savingslanding,
  savingaccountview,
  accounttransaction,
  walletmanager
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    storage.removeItem('persist:root')
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
