import api from './index'


export async function getOrgInfo(orgId) {
    return await api.get(`org/${orgId}`)
}

export async function getOrgStats(orgId) {
    return await api.get(`org/${orgId}/stats`)
}

export async function getOrgAccounts(orgId) {
    return await api.get(`org/${orgId}/accounts`)
}

export async function getOrgTransactions(orgId) {
    return await api.get(`org/${orgId}/transactions`)
}

export async function getOrgFromCustomer(customerId) {
    return await api.get(`org/customer/${customerId}`)
}

export async function getAccountsFromCustomer(customerId) {
    return await api.get(`accounts/1/50`)
}
