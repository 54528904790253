import * as actions from '../../actions/savingaccountview'

// ** Initial State
const initialState = {
    accountBalanceDetails: {},
    accountTransactionsDetails: [],
    accountInterestDetails: [],
    accountStats: []
  }

  const savingsAccountViewReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.LOAD_ACCOUNT_BALANCE:
        return { ...state, accountBalanceDetails: action.payload}
      case actions.LOAD_ACCOUNT_TRANSACTIONS:
        return { ...state, accountTransactionsDetails: action.payload}
      case actions.LOAD_ACCOUNT_INTEREST:
        return { ...state, accountInterestDetails: action.payload}
      case actions.LOAD_ACCOUNT_STATS:
        return { ...state, accountStats: action.payload}
      default:
          return state
    }
  }
  
  export default savingsAccountViewReducer
  