import * as actions from '../../actions/savingsapp'

// ** Initial State
const initialState = {
    assets: [],
    rates: [],
    accounts: [],
    newAccountDetails: {},
    closeAccountDetails: {},
    paymentData: {},
    authenticated: true,
    accountActivityIndicator: {},
    modal2fa: false,
    error: {},
    displayToast: {
      type: '',
      header: '',
      message: ''
    },
    estimatedTransactionFee: {}
  }

  const savingsAppReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.LOAD_EFFECTIVE_RATES:
        return { ...state, rates: action.payload}
      case actions.LOAD_ASSET_BALANCES:
        return { ...state, assets: action.payload}
      case actions.LOAD_SAVINGS_ACCOUNTS:
        return { ...state, accounts: action.payload}
      case actions.REQUEST_NEW_ACCOUNT:
        return { ...state, newAccountDetails: action.payload}
      case actions.REQUEST_CLOSE_ACCOUNT:
        return { ...state, closeAccountDetails: action.payload}
      case actions.SUBMIT_NEW_ACCOUNT_SUCCESS:
        return { ...state, displayToast: action.toast, newAccountDetails: {} }
      case actions.SUBMIT_CLOSE_ACCOUNT_SUCCESS:
        return { ...state, displayToast: action.toast, closeAccountDetails: {} }
      case actions.SUBMIT_CLOSE_ACCOUNT_ERROR:
        return { ...state, displayToast: action.toast, closeAccountDetails: {} }
      case actions.SUBMIT_NEW_ACCOUNT_ERROR:
        return { ...state, displayToast: action.toast, newAccountDetails: {} }
      case actions.UNAUTHENTICATED_REQUEST:
        return { ...state, authenticated: action.authenticated }
      case actions.REQUEST_COINBASE_2FA_CODE:
        return { ...state, modal2fa: action.modal2fa }
      case actions.ERROR_LOADING_DATA:
        return { ...state, displayToast: action.toast }
      case actions.WARNING_VALIDATION:
        return { ...state, displayToast: action.toast }
      case actions.LOAD_ESTIMATED_TRANSACTION_FEE:
        return { ...state, estimatedTransactionFee: action.payload}
      case actions.LOAD_PAYMENT_DATA:
        return { ...state, paymentData: action.payload}
      default:
          return state
    }
  }
  
  export default savingsAppReducer
  