import api from './index'

export async function coinbaseExchangeCode(code) {
    return await api.post(`/coinbase/auth/${code}`, {
    
    })
}

export async function getBalancesFromCoinbase(customerId) {
    return await api.post(`/coinbase/balance/${customerId}`, {
    
    })
}

export async function disconnectWallet(customerId) {
    return await api.post(`/coinbase/revoke/${customerId}`)
}