// ** JWT Service Import
import JwtService from './jwtService'

// ** Export Service as useJwt
export default function useJwt(api, jwtOverrideConfig) {
  const jwt = new JwtService(api, jwtOverrideConfig)

  return {
    jwt
  }
}
