import * as actions from '../../actions/wallet'

// ** Initial State
const initialState = {
    coinbase: {
      assets: {},
      connected: false
    },
    metamask: {
      account: '',
      assets: {},
      connected: false,
      chainId: 1
    }
  }

  const walletReducer = (state = initialState, action) => {
    switch (action.type) {
      case actions.CONNECT_METAMASK_WALLET:
        return { ...state, metamask: action.metamask }
      case actions.CONNECT_COINBASE_WALLET:
        return { ...state, coinbase: action.coinbase }
      case actions.DISCONNECT_METAMASK_WALLET:
        return { ...state, metamask: initialState.metamask }
      case actions.DISCONNECT_COINBASE_WALLET:
        return { ...state, coinbase: initialState.coinbase }
      default:
          return state
    }
  }
  
  export default walletReducer
  