import { handleLogin, displayLoader, hideLoader, handleLogout, displayToast, handleUnauthenticatedRequests } from '../../actions/common'
import { handleError } from '../common/index'

const DEV_ENV = process.env.NODE_ENV === 'development'

// Async Actions
export const DEPOSIT = 'DEPOSIT'
export const WITHDRAW = 'WITHDRAW'

// State Actions
export const SELECT_DESTINATION_WALLET = 'SELECT_DESTINATION_WALLET'
export const SELECT_SOURCE_WALLET = 'SELECT_SOURCE_WALLET'
export const SET_AMOUNT = 'SET_AMOUNT'