import { getTokenBalanceByAccount } from '../../../api/wallet'
import { coinbaseExchangeCode, disconnectWallet } from '../../../api/coinbase'

import { displayLoader, hideLoader, handleLogout, displayToast, handleUnauthenticatedRequests } from '../../actions/common'
import { handleError } from '../common/index'

const DEV_ENV = process.env.NODE_ENV === 'development'

// Async Actions
export const CONNECT_METAMASK_WALLET = 'CONNECT_METAMASK_WALLET'
export const CONNECT_COINBASE_WALLET = 'CONNECT_COINBASE_WALLET'

export const DISCONNECT_METAMASK_WALLET = 'DISCONNECT_METAMASK_WALLET'
export const DISCONNECT_COINBASE_WALLET = 'DISCONNECT_COINBASE_WALLET'

// State Actions
export const GET_WALLET_INFO = 'GET_WALLET_INFO'


export function connectMetamaskWallet(provider) {
    return async function connectMetamaskWalletThunk(dispatch, getState) {
        const account = provider?.selectedAddress
        await getTokenBalanceByAccount(account).then((response) => {
            return response.data
        }).then((assets) => {
            // let filteredAssets = []
            // for (const )
        }).then((assets) => {
            dispatch({ type: CONNECT_METAMASK_WALLET,
                        metamask: {
                            account,
                            chainId: provider?.chainId,
                            connected: true,
                            assets
                        }
            })
            displayToast('success', 'Succesfully found and connected your Metamask wallet!')
        }).then(() => {
            // dispatch(hideLoader()) // TODO: Needs better handling asset load time slow
        }).catch((error) => { handleError(dispatch, error) })
    }
}

export function disconnectMetamask() {

}

export function connectCoinbaseAccountWallet(code) {
    return async function connectCoinbaseAccountWalletThunk(dispatch, getState) {
        await coinbaseExchangeCode(code).then((response) => {
            // dispatch(handleLogin(user, history))
        }).then(() => {
            displayToast('success', 'Succesfully connected your Coinbase account wallet!')
        }).catch((error) => { handleError(dispatch, error) })
    }
}

export function disconnectCoinbaseAccountWallet(customerID) {
    return async function disconnectCoinbaseAccountWalletThunk(dispatch, getState) {
        await disconnectWallet(customerId).then((response) => {
            return response.data
        }).then(() => {
            displayToast('success', 'Your Coinbase account wallet has been succcessfully disconnected.')
        }).catch((error) => { handleError(dispatch, error) })

    }
}

export async function loadCoinbaseAssetBalances() {}

/* export async function updateInjectedWalletAssetBalances(account) {
    return async function updateInjectedWalletAssetBalancesThunk(dispatch, getState) {
        await getTokenBalanceByAccount(account).then((assets) => {
            console.log(assets)
        })
    }
} */

export async function addFundsWithOnramper() {}
