import {batch} from 'react-redux'
import {getEffectiveRates, getInterestEstimate} from '../../../api/rates'
import {getBalancesFromCoinbase, disconnectWallet, coinbaseExchangeCode} from '../../../api/coinbase'
import {openSavingsAccount, closeSavingsAccount, openSavingsAccountWithOnRamper} from '../../../api/accounts'
import {getAccountsFromCustomer} from '../../../api/org'
import {getTransactionFeeEstimation} from '../../../api/wallet'
import {
    handleLogin,
    displayLoader,
    hideLoader,
    handleLogout,
    displayToast
} from '../../actions/common'
import api from '../../../api'
import jwt from 'jsonwebtoken'
import {handleError} from '../common/index'

const DEV_ENV = process.env.NODE_ENV === 'development'


// Async Actions
export const LOAD_EFFECTIVE_RATES = 'LOAD_EFFECTIVE_RATES'
export const LOAD_ASSET_BALANCES = 'LOAD_ASSET_BALANCES'
export const LOAD_SAVINGS_ACCOUNTS = 'LOAD_SAVINGS_ACCOUNTS'
export const SUBMIT_NEW_ACCOUNT_SUCCESS = 'SUBMIT_NEW_ACCOUNT_SUCCESS'
export const SUBMIT_NEW_ACCOUNT_ERROR = 'SUBMIT_NEW_ACCOUNT_ERROR'
export const SUBMIT_CLOSE_ACCOUNT_SUCCESS = 'SUBMIT_CLOSE_ACCOUNT_SUCCESS'
export const SUBMIT_CLOSE_ACCOUNT_ERROR = 'SUBMIT_CLOSE_ACCOUNT_ERROR'
export const LOAD_ESTIMATED_TRANSACTION_FEE = 'LOAD_ESTIMATED_TRANSACTION_FEE'
export const LOAD_PAYMENT_DATA = 'LOAD_PAYMENT_DATA'


// State Actions
export const REQUEST_NEW_ACCOUNT = 'REQUEST_NEW_ACCOUNT'
export const REQUEST_COINBASE_2FA_CODE = 'REQUEST_COINBASE_2FA_CODE'
export const REQUEST_CLOSE_ACCOUNT = 'REQUEST_CLOSE_ACCOUNT'
export const WARNING_VALIDATION = 'WARNING_VALIDATION'
export const DISCONNECTED_WALLET = 'DISCONNECTED_WALLET'

export const CLOSE_SAVING_ACCOUNT = 'CLOSE_SAVING_ACCOUNT'
export const DEPOSIT_SAVING_ACCOUNT = 'DEPOSIT_SAVING_ACCOUNT'
export const WITHDRAW_SAVING_ACCOUNT = 'WITHDRAW_SAVING_ACCOUNT'
export const UNAUTHENTICATED_REQUEST = 'UNAUTHENTICATED_REQUEST'
export const ERROR_LOADING_DATA = 'ERROR_LOADING_DATA'

export function loadEstimatedTransactionFee() {
    return async function loadEstimatedTransactionFeeThunk(dispatch, getState) {
        const rates = await getTransactionFeeEstimation().then((response) => {
            dispatch({type: LOAD_ESTIMATED_TRANSACTION_FEE, payload: response.data})

            return response.data.rates
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}

export function loadEffectiveRates() {
    return async function loadEffectiveRatesThunk(dispatch, getState) {
        const rates = await getEffectiveRates().then((response) => {
            dispatch({type: LOAD_EFFECTIVE_RATES, payload: response.data.rates})

            return response.data.rates
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}

export function loadAssetBalances(customerId) {
    return async function loadAssetBalancesThunk(dispatch, getState) {
        await getBalancesFromCoinbase(customerId).then((response) => {
            const balances = response.data.filter((asset) => {
                if (['USDC'].indexOf(asset.asset) > -1) {
                    asset.availableForSavings = true
                    asset.demo = false

                    if (DEV_ENV) {
                        asset.availableForSavings = false
                    }

                    if (asset.asset === 'BTC') {
                        asset.availableForSavings = false
                    }

                    return asset
                }
            })

            if (DEV_ENV) {
                // Demo funds
                balances.push({
                    asset: 'USDC',
                    balance: '100',
                    availableForSavings: true,
                    demo: true
                })
            }

            dispatch({type: LOAD_ASSET_BALANCES, payload: balances})
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}

export function loadCustomerSavingAccounts(customerId) {
    return async function loadCustomerSavingsAccountThunk(dispatch, getState) {
        const accounts = await getAccountsFromCustomer(customerId).then((response) => {
            dispatch({type: LOAD_SAVINGS_ACCOUNTS, payload: response.data})

            return response.data
        }).catch((error) => {
            handleError(dispatch, error)
        })


    }
}

export function disconnectWalletInfo() {
    displayToast('info', 'Your Coinbase wallet has been succesfully disconnected.')
    return ({
        type: DISCONNECTED_WALLET,
        toast: {
            type: 'info',
            header: 'Wallet disconnected',
            message: 'Your Coinbase wallet has been succesfully disconnected.'
        }
    })
}

export function connectedWalletSuccess() {
    displayToast('success', 'Your Coinbase wallet has been succesfully connected!')
    return ({
        type: DISCONNECTED_WALLET,
        toast: {
            type: 'success',
            header: 'Wallet connected',
            message: 'Your Coinbase wallet has been succesfully connected!'
        }
    })
}

export function submitDisconnectCoinbaseWallet(customerId, history) {
    return async function submitDisconnectCoinbaseWalletThunk(dispatch, getState) {

        await disconnectWallet(customerId).then((response) => {
            dispatch(handleLogout())
            return response.data
        }).then(() => {
            dispatch(disconnectWalletInfo())
            history.push(`/savings/landing`)
        }).catch((error) => {
            handleError(dispatch, error)
        })

    }
}

export function submitNewSavingAccount(asset, amount, customerId, code2fa = '') {
    return async function submitNewAccountThunk(dispatch, getState) {
        dispatch(displayLoader())
        await openSavingsAccount(asset, amount, customerId, code2fa).then((response) => {
            dispatch({
                type: SUBMIT_NEW_ACCOUNT_SUCCESS,
                toast: {
                    type: 'success',
                    header: 'Account Succesfully Created',
                    message: 'We have successfully received your account order! Please wait several minutes for transactions to confirm and we activate your account so you cant start earning more!!'
                }
            })
            displayToast('success', 'We have successfully received your account order! Please wait several minutes for transactions to confirm and we activate your account so you cant start earning more!!')
        }).catch((error) => {
            handleError(dispatch, error)
        })

        await getAccountsFromCustomer(customerId).then((response) => {
            dispatch({type: LOAD_SAVINGS_ACCOUNTS, payload: response.data})
            dispatch(hideLoader())

            return response.data
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}

export function submitCloseSavingsAccount(accountId, customerId) {
    return async function closeAccountThunk(dispatch, getState) {
        dispatch(displayLoader())
        const accounts = await closeSavingsAccount(accountId).then((response) => {
            dispatch({
                type: SUBMIT_CLOSE_ACCOUNT_SUCCESS,
                toast: {
                    type: 'success',
                    header: 'Account Succesfully Closed.',
                    message: 'We are sad to close your account. But let us know we will be here to earn you more whenever you need it!'
                }
            })
            displayToast('success', 'We are sad to close your account. But let us know we will be here to earn you more whenever you need it!')
            return response.data
        }).then(() => {
            const accounts = getAccountsFromCustomer(customerId).then((response) => {
                dispatch({type: LOAD_SAVINGS_ACCOUNTS, payload: response.data})
                dispatch(hideLoader())

                return response.data
            })
            return accounts
        }).catch((error) => {
            handleError(dispatch, error)
        })

    }
}

export function authenticateCoinbaseConnectWallet(code, history) {
    return async function authenticateCoinbaseConnectWalletThunk(dispatch, getState) {
        await coinbaseExchangeCode(code).then((response) => {
            const token = response.data.jwt
            const user = jwt.decode(token)
            api.defaults.headers.common = {Authorization: `Bearer ${token}`}
            dispatch(handleLogin(user, history))
        }).then(() => {
            history.replace(`/savings/app`)
            dispatch(connectedWalletSuccess())
        })
    }
}

export function requestNewAccount(asset, amount) {
    return ({
        type: REQUEST_NEW_ACCOUNT,
        payload: {
            amount,
            asset
        }
    })
}

export function requestCloseAccount(accountId) {
    return ({
        type: REQUEST_CLOSE_ACCOUNT,
        payload: {
            accountId
        }
    })
}

export function close2FARequest() {
    return ({
        type: REQUEST_COINBASE_2FA_CODE,
        modal2fa: false
    })
}

export function validationWarning(message) {
    displayToast('warning', message)
    return ({
        type: WARNING_VALIDATION,
        toast: {
            type: 'warning',
            header: 'Oops! Seems like we encountered a problem with your submission.',
            message
        }
    })
}

export function completedOnRamperPayment(paymentData) {
    return ({
        type: LOAD_PAYMENT_DATA,
        payload: paymentData
    })
}

export function addFundsToAccountWithOnramper(paymentData, customerId) {
    return async function addFundsToAccountWithOnramperThunk(dispatch, getState) {
        displayLoader()
        await openSavingsAccountWithOnRamper(paymentData, customerId).then(() => {
        }).then(() => {
            displayToast('success', 'Your savings wallet and APY rate will open and be confirmed within 48 hours. We well send email once confirmed')
            batch(() => {
                dispatch(completedOnRamperPayment({}))
                dispatch(loadCustomerSavingAccounts(customerId))
            })
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}

export function loadEffectiveRatesWithEstimates() {
    return async function loadEffectiveRatesThunk(dispatch, getState) {
        await getEffectiveRates().then((response) => {
            dispatch({type: LOAD_EFFECTIVE_RATES, payload: response.data})
        }).catch((error) => {
            handleError(dispatch, error)
        })
    }
}
