// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

// ** Redux Imports
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer, toast } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/assets/fonts/inter/inter.scss'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import './index.scss'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0

if (SENTRY_DSN) {
  console.log('Activating Sentry Monitoring')
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: SENTRY_SAMPLE_RATE 
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover />
        </ThemeContext>
      </PersistGate>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
